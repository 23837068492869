.search {
	background: #FFF;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 5px 10px fade(#124165,44%);
	display: flex;
	.resp(767px,{
		display: block;
		background: none;
		border-radius: 0;
		box-shadow: none;
		max-width: 480px;
		margin: 0 auto 20px;
		overflow: visible;
	});
	label {
		font-size: 16px;
		line-height: 20px;
		display: block;
	}
	> fieldset {
		flex: 1 1 auto;
		margin: 10px 0;
		padding: 0 20px;
		box-sizing: border-box;
		.resp(767px,{
			background: #FFF;
			overflow: hidden;
			border-radius: 5px;
			box-shadow: 0 5px 10px fade(#124165,44%);
			padding: 10px 20px;
			flex: 0 0 auto;
			max-width: none;
			margin: 0 0 10px 0;
		});
	}
	.search-go {
		flex: 0 0 auto;
		.button {
			border-radius: 0 5px 5px 0;
			padding: 0 80px;
			.resp(1279px,{
				font-size: 20px;
			});
			.resp(1050px,{
				font-size: 20px;
				line-height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: normal;
				text-align: center;
			});
			.resp(767px,{
				border-radius: 5px;
				display: block;
				width: 100%;
				display: block;
				padding: 0;
			});
		}
	}
}