.audit-check-box {
	flex: 1 1 auto;
	background: #FFF;
	padding: 23px 20px;
	border-radius: 5px;
	transition: box-shadow @hover;
	.resp(767px,{
		padding: 15px;
	});
	&:hover {
		box-shadow: 0 5px 10px fade(#989898,22);
	}
	.audit-check-header {
		position: relative;
		padding-left: 82px;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 16px;
		.resp(767px,{
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 10px;
			padding-left: 75px;
		});
		&:hover {
			&:before {
				transform: scale(1.1);
			}
		}
		&:after {
			content:'';
			min-height: inherit;
			font-size: 0;
			flex: 0 0 auto;
			width: 0;
			display: block;
		}
		&:before {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -30px;
			width: 60px;
			height: 60px;
			content: '';
			background: url('../img/check.svg') no-repeat;
			background-size: 100% 100%;
			transition: transform @hover;
		}
		> span {
			display: table-cell;
			min-height: 64px;
			vertical-align: middle;
		}
	}
	.audit-check-info {
		margin: 0;
		padding: 0;
		list-style-type: none;
		> li {
			font-size: 16px;
			line-height: 24px;
			padding-left: 19px;
			position: relative;
			display: block;
			width: auto;
			margin: 10px 0 0 0;
			&:first-child {
				margin: 0;
			}
			&:before {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: @link;
				position: absolute;
				left: 0;
				top: 10px;
				content: '';
				box-shadow: 0 2px 5px fade(@link,44%);
			}
		}
	}
}

.audit-check-list {
	margin: 0 0 -30px -30px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	.resp(1050px,{
		margin: 0 0 -20px 20px;
	});
	.resp(767px,{
		display: block;
		margin-left: 0;
	});
	> li {
		width: 33.3333%;
		box-sizing: border-box;
		padding-left: 30px;
		margin-bottom: 30px;
		flex: 0 0 auto;
		display: flex;
		align-items: stretch;
		.resp(1050px,{
			width: 50%;
			margin-bottom: 20px;
			padding-left: 20px;
		});
		.resp(767px,{
			width: auto;
			max-width: 380px;
			margin: 0 auto 20px;
			display: block;
			padding: 0;
		});
	}
}