.reviews-box {
	border-radius: 3px;
	background: #FFF;
	margin-bottom: 10px;
	display: flex;
	align-items: flex-start;
	padding: 27px 30px;
	transition: box-shadow @hover;
	.resp(767px,{
		padding: 20px;
		display: block;
		position: relative;
	});
	&:hover {
		box-shadow: 0 5px 10px fade(#989898,22);
	}
	.reviews-box-content {
		flex: 1 1 auto;
		.resp(900px,{
			font-size: 16px;
			line-height: 24px;
		});
		.resp(767px,{
			flex: 0 0 auto;
		});
		.reviews-box-info {
			display: flex;
			margin-bottom: 4px;
			.resp(767px,{
				margin-bottom: 10px;
				display: block;
				line-height: 19px;
				padding-left: 55px;
			});
			.reviews-box-author {
				color: @link;
				flex: 0 0 auto;
				margin-right: 15px;
				.resp(767px,{
					margin: 0;
				});
			}
			.reviews-box-date {
				color: #999;
				flex: 0 0 auto;
			}
		}
		.reviws-box-text {
			> p {
				margin: 10px 0 0 0;
				&:first-child {
					margin: 0;
				}
			}
		}
	}
	.reviews-box-image {
		width: 70px;
		margin-right: 30px;
		position: relative;
		flex: 0 0 auto;
		.resp(767px,{
			width: 38px;
			margin: 0;
			position: absolute;
			left: 20px;
			top: 20px;
		});
		> img {
			width: 100%;
			display: block;
			border-radius: 1px;
		}
		&.fb {
			&:before {
				background-image: url('../img/fb.svg');
				background-size: 7px 16px;
			}
		}
		&.vk {
			&:before {
				background-image: url('../img/vk.svg');
				background-size: 19px 9px;
			}
		}
		&.fb,
		&.vk {
			&:before {
				width: 30px;
				height: 30px;
				display: block;
				background-color: @link;
				background-repeat: no-repeat;
				background-position: center center;
				position: absolute;
				right: -6px;
				bottom: -6px;
				content: '';
				transition: background-color @hover;
				border-radius: 50%;
				&:hover {
					background-color: @linkhover;
				}
			}
		}
	}
}

.reviews-nav {
	padding: 38px 0 42px 0;
	.resp(1050px,{
		padding: 25px 0 29px 0;
	});
	> ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		.resp(1050px,{
			justify-content: center;
		});
		.resp(600px,{
			display: block;
			text-align: center;
		});
		> li {
			margin-left: 55px;
			flex: 0 0 auto;
			font-weight: bold;
			font-size: 42px;
			line-height: 60px;
			.resp(1050px,{
				font-size: 32px;
				line-height: 40px;
			});
			.resp(767px,{
				font-size: 24px;
				line-height: 32px;
			});
			.resp(600px,{
				margin: 15px 0 0 0;
			});
			&:first-child {
				margin: 0;
			}
			> a {
				border-bottom: 2px dashed @link;
				transition: color @hover, border-color @hover;
				&:hover {
					border-color: @linkhover;
				}
			}
		}
	}
	.active {
		> a {
			&,
			&:hover {
				color: #000;
				border-color: transparent;
			}
		}
	}
}

.reviews-type {
	padding-bottom: 100px;
	.resp(1279px,{
		padding-bottom: 70px;
	});
	.resp(767px,{
		padding-bottom: 60px;
	});
	&.bg {
		padding-top: 60px;
	}
}

.reviews-video-list {
	margin: 0 0 -30px -30px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(900px,{
		display: block;
		margin: 0 0 -20px 0;
	});
	> li {
		width: 50%;
		box-sizing: border-box;
		padding-left: 30px;
		margin-bottom: 30px;
		flex: 0 0 auto;
		.resp(900px,{
			width: auto;
			padding: 0;
		});
	}
}