.case-result {
	border: 10px solid @linkhover;
	border-radius: 3px;
	box-shadow: 0 10px 21px fade(#cad0d3,36%);
	background: #FFF;
	padding: 38px 95px 54px 95px;
	display: flex;
	justify-content: space-between;
	.resp(1279px,{
		padding-left: 30px;
		padding-right: 30px;
	});
	.resp(1050px,{
		padding: 30px 0;
		justify-content: flex-start;
	});
	.resp(600px,{
		display: block;
		padding: 20px;
		max-width: 220px;
		margin: 0 auto;
	});
	.case-result-box {
		flex: 0 0 auto;
		display: flex;
		align-items: flex-end;
		.resp(1050px,{
			display: block;
			text-align: center;
			width: 33.3333%;
			padding: 0 10px;
			box-sizing: border-box;
		});
		.resp(600px,{
			width: auto;
			padding: 0;
			display: block;
			margin-bottom: 20px;
			&:last-child {
				margin: 0;
			}
		});
		.case-result-info {
			font-size: 18px;
			line-height: 24px;
			flex: 0 0 auto;
			> span {
				display: block;
				font-size: 42px;
				line-height: 48px;
				color: @link;
				font-weight: bold;
				margin-bottom: 1px;
			}
		}
		.case-result-title {
			font-size: 24px;
			line-height: 28px;
			margin-right: 30px;
			flex: 0 0 auto;
			font-weight: bold;
			text-transform: uppercase;
			.resp(1050px,{
				margin: 0 0 10px 0;
			});
		}
	}
}

.case-result-wrapper {
	padding: 60px 0 0 0;
}