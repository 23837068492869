.success {
	> li {
		.respMin(1051px,{
			width: 25%;
		});
		.respMin(768px,{
			width: 50%;
		});
		&:nth-child(1){
			> .icon-list-header {
				&:before {
					width: 61px;
					height: 58px;
					background-image: url('../img/success-1.svg');
				}
			}
		}
		&:nth-child(2){
			> .icon-list-header {
				&:before {
					width: 64px;
					height: 54px;
					background-image: url('../img/success-2.svg');
				}
			}
		}
		&:nth-child(3){
			> .icon-list-header {
				&:before {
					width: 63px;
					height: 57px;
					background-image: url('../img/success-3.svg');
				}
			}
		}
		&:nth-child(4){
			> .icon-list-header {
				&:before {
					width: 60px;
					height: 60px;
					background-image: url('../img/success-4.svg');
				}
			}
		}
	}
}