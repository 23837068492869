.footer-bottom {
	padding: 45px 0;
	font-size: 16px;
	line-height: 22px;
	margin-left: -30px;
	display: flex;
	.resp(1279px,{
		padding: 30px 0;
		margin: 0;
	});
	.resp(1050px,{
		position: relative;
	});
	.resp(767px,{
		display: block;
		text-align: center;
	});
	.copyright {
		width: 33.3333%;
		padding-left: 30px;
		box-sizing: border-box;
		flex: 0 0 auto;
		color: #999;
		.resp(1279px,{
			padding: 0;
			margin-right: 40px;
			width: 290px;
			padding: 0;
		});
		.resp(1050px,{
			width: auto;
			margin: 0;
			position: absolute;
			left: 0;
			top: 30px;
		});
		.resp(767px,{
			position: relative;
			top: 0;
			margin-bottom: 13px;
		});
	}
	.footer-bottom-nav {
		width: 66.6666%;
		box-sizing: border-box;
		flex: 0 0 auto;
		padding-left: 30px;
		.resp(1279px,{
			padding: 0;
			flex: 1 1 auto;
		});
		.resp(1050px,{
			width: 100%;
		});
		> ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			.resp(1279px,{
				justify-content: space-between;
			});
			.resp(1050px,{
				flex-wrap: wrap;
			});
			.resp(767px,{
				display: block;
			});
			> li {
				flex: 0 0 auto;
				margin-bottom: 13px;
				.respMin(1280px,{
					&:nth-child(1) {
						width: 35%;
					}
					&:nth-child(2) {
						width: 30%;
					}
					&:nth-child(3) {
						width: 35%;
					}
				});
				.resp(1050px,{
					&:nth-child(1) {
						width: 100%;
						text-align: right;
					}
				});
				.resp(767px,{
					&:nth-child(1) {
						text-align: center;
					}
				});
			}
		}
	}
}

.footer-main {
	margin-left: -30px;
	display: flex;
	padding: 90px 0 55px 0;
	.resp(1279px,{
		padding: 60px 0 30px 0;
		margin: 0;
	});
	.resp(1050px,{
		display: block;
		padding: 55px 0 25px 0;
	});
	.resp(767px,{
		padding: 50px 0 15px 0;
	});
	.footer-logo-social {
		width: 33.3333%;
		padding-left: 30px;
		box-sizing: border-box;
		flex: 0 0 auto;
		.resp(1279px,{
			padding: 0;
			margin-right: 40px;
			width: 290px;
		});
		.resp(1050px,{
			width: auto;
			margin: 0 0 30px 0;
			padding: 0;
			display: flex;
			justify-content: space-between;
		});
		.resp(767px,{
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 60px;
		});
		.footer-logo {
			font-weight: bold;
			font-size: 43px;
			line-height: 46px;
			text-transform: uppercase;
			flex: 0 0 auto;
			display: inline-block;
			vertical-align: top;
			margin-bottom: 15px;
			&:hover {
				> span {
					color: @link;
					animation: pulse 1s ease-in-out infinite;
				}
			}
			> span {
				color: @text;
				font-size: 23px;
				font-weight: normal;
				position: relative;
				top: -6px;
				margin-right: 6px;
				transition: color @hover;
				float: left;
			}
		}
		.social {
			.resp(767px,{
				flex: 1 1 100%;
				max-width: 110%;
				justify-content: center;
			});
		}
	}
	.footer-nav-contact {
		width: 66.6666%;
		box-sizing: border-box;
		flex: 0 0 auto;
		padding-left: 30px;
		display: flex;
		.resp(1279px,{
			justify-content: space-between;
			width: auto;
			padding: 0;
			flex: 1 1 auto;
		});
		.resp(767px,{
			flex-wrap: wrap;
		});
		.contact-info {
			flex: 0 0 auto;
			.respMin(1280px,{
				width: 35%;
			});
			.resp(767px,{
				width: 100%;
				text-align: center;
				padding-top: 44px;
			});
			.footer-contact-phone {
				margin: 0 -15px 26px 0;
				.resp(767px,{
					margin: 0 0 24px 0;
				});
			}
		}
		.footer-nav {
			flex: 0 0 auto;
			.respMin(1280px,{
				width: 35%;
				& + .footer-nav {
					width: 30%;
				}
			});
			.resp(767px,{
				width: 50%;
				padding: 0 10px;
				box-sizing: border-box;
				text-align: center;
			});
			> ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
				> li {
					margin-top: 6px;
					&:first-child {
						margin: 0;
					}
				}
			}
		}
	}
}