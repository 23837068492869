.education-intro {
	padding: 6px 0 60px 0;
	box-sizing: border-box;
	min-height: 550px;
	background: url('../img/education-intro.jpg') no-repeat right top;
	.resp(1100px,{
		background-size: auto 100%;
		min-height: 0;
	});
	.resp(900px,{
		background-size: auto 80%;
		background-position: right center;
	});
	.resp(767px,{
		background: none;
		text-align: center;
	});
	> h2 {
		margin-bottom: 50px;
		.resp(1050px,{
			text-align: left;
		});
		.resp(767px,{
			text-align: center;
			margin: 0 0 -15px 0;
			position: relative;
			z-index: 2;
		});	
		> em {
			font-weight: normal;
			max-width: 560px;
			font-style: normal;
			display: block;
			.resp(1050px,{
				font-size: 28px;
				max-width: 320px;
			});
			.resp(767px,{
				font-size: 24px;
				line-height: 32px;
				max-width: 280px;
				margin: 0 auto;
			});
		}
		> span {
			color: @linkhover;
			.resp(1050px,{
				display: block;
			});	
		}
	}
	.education-intro-mobile {
		display: none;
		.resp(767px,{
			display: block;
			margin: 0 auto;
			position: relative;
			z-index: 1;
		});
	}
}