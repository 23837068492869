.popular {
	margin: 0 0 -40px -30px;
	.resp(1050px,{
		margin: 0 0 -30px -20px;
	});
	.resp(767px,{
		margin: 0;
		display: block;
	});
	.owl-carousel {
		display: flex;
		.resp(767px,{
			display: block;
		});
	}
	.news-box {
		padding-left: 30px;
		margin-bottom: 40px;
		width: 25%;
		box-sizing: border-box;
		flex: 0 0 auto;
		.resp(1050px,{
			width: 33.3333%;
			padding-left: 20px;
			margin-bottom: 30px;
			&:last-child {
				display: none;
			}
		});
		.resp(767px,{
			width: auto;
			padding: 0;
			margin: 0;
			&:last-child {
				display: block;
			}
		});
	}
}