.why {
	> li {
		.respMin(768px,{
			width: 33.3333%;
		});
		&:nth-child(1){
			> .icon-list-header {
				&:before {
					width: 63px;
					height: 63px;
					background-image: url('../img/why-1.svg');
				}
			}
		}
		&:nth-child(2){
			> .icon-list-header {
				&:before {
					width: 56px;
					height: 63px;
					background-image: url('../img/why-2.svg');
				}
			}
		}
		&:nth-child(3){
			> .icon-list-header {
				&:before {
					width: 58px;
					height: 59px;
					background-image: url('../img/why-3.svg');
				}
			}
		}
	}
}