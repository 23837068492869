.contact-form {
	background: @link;
	h2 {
		color: #FFF;
		margin-bottom: 20px;
		.resp(1050px,{
			font-size: 32px;
			line-height: 38px;
			text-align: center;
			max-width: 480px;
			margin-left: auto;
			margin-right: auto;
		});
	}
	.contact-form-text {
		font-size: 20px;
		font-weight: 300;
		margin-bottom: 33px;
		.resp(1050px,{
			max-width: 440px;
			margin: 0 auto 30px;
			text-align: center;
		});
		.resp(767px,{
			font-size: 18px;
			line-height: 26px;
			margin-bottom: 23px;
		});
	}
}