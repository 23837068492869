.settings-why-google {
	background: @bg;
	margin-bottom: 10px;
	.icon-list {
		> li {
			.respMin(768px,{
				width: 33.3333%;
			});
			&:nth-child(1){
				> .icon-list-header {
					&:before {
						width: 62px;
						height: 67px;
						background-image: url('../img/settings-why-google-1.svg');
					}
				}
			}
			&:nth-child(2){
				> .icon-list-header {
					&:before {
						width: 68px;
						height: 60px;
						background-image: url('../img/settings-why-google-2.svg');
					}
				}
			}
			&:nth-child(3){
				> .icon-list-header {
					&:before {
						width: 61px;
						height: 62px;
						background-image: url('../img/settings-why-google-3.svg');
					}
				}
			}
		}
	}
}
