.page-header {
	min-height: 400px;
	padding: 40px 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	.resp(1050px,{
		padding: 32px 0;
		min-height: 220px;
		text-align: center;
	});
	.resp(767px,{
		padding: 14px 0;
		min-height: 0;
		display: block;
	});
	& + .goal-wrapper {
		margin-top: 60px;
		.resp(1050px,{
			margin-top: 40px;
		});
		.resp(767px,{
			margin-top: 30px;
		});
	}
	&:after {
		content:'';
		min-height: inherit;
		font-size: 0;
		flex: 0 0 auto;
		width: 0;
		display: block;
	}
	h1 {
		margin: 0;
		max-width: 1040px;
		.resp(1050px,{
			max-width: 620px;
			margin: 0 auto;
		});
		.resp(767px,{
			max-width: 480px;
		});
	}
	p {
		font-size: 26px;
		line-height: 32px;
		color: #FFF;
		margin: 17px 0 0 0;
		max-width: 920px;
		.resp(767px,{
			max-width: 480px;
			font-size: 16px;
			line-height: 24px;
			margin: 10px auto 0;
		});
	}
	.page-header-content {
		flex: 1 1 auto;
	}
	.search {
		margin-top: 34px;
		.resp(1050px,{
			text-align: left;
		});
	}
}