.knowledge-links {
	margin: 0 0 -30px -30px;
	padding: 0 0 52px 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(1050px,{
		margin: 0 0 -25px -20px;
		padding-bottom: 40px;
	});
	.resp(767px,{
		margin: 0 0 -20px -20px;
		padding-bottom: 20px;
	});
	> li {
		padding: 0 40px 0 117px;
		margin-bottom: 30px;
		box-sizing: border-box;
		width: 33.3333%;
		flex: 0 0 auto;
		font-size: 22px;
		line-height: 30px;
		position: relative;
		.resp(1279px,{
			padding-right: 0;
		});
		.resp(1050px,{
			padding-left: 107px;
			margin-bottom: 25px;
			width: 50%;
		});
		.resp(767px,{
			width: 100%;
			margin-bottom: 20px;
		});
		&.know-14{
			&:before {
				width: 63px;
				height: 56px;
				background-image: url('../img/knowledge-1.svg');
			}
		}
		&.know-15{
			&:before {
				width: 63px;
				height: 56px;
				background-image: url('../img/knowledge-2.svg');
			}
		}
		&.know-16{
			&:before {
				width: 63px;
				height: 62px;
				background-image: url('../img/success-2.svg');
			}
		}
		&.know-17{
			&:before {
				width: 56px;
				height: 56px;
				background-image: url('../img/knowledge-4.svg');
			}
		}
		&.know-18{
			&:before {
				width: 60px;
				height: 60px;
				background-image: url('../img/success-4.svg');
			}
		}
		&.know-19{
			&:before {
				width: 64px;
				height: 54px;
				background-image: url('../img/knowledge-6.svg');
			}
		}
		&.know-20{
			&:before {
				width: 63px;
				height: 57px;
				background-image: url('../img/success-3.svg');
			}
		}
		&.know-21{
			&:before {
				width: 63px;
				height: 53px;
				background-image: url('../img/goal.svg');
			}
		}
		&.know-22{
			&:before {
				width: 61px;
				height: 58px;
				background-image: url('../img/success-1.svg');
			}
		}
		&:hover {
			&:before {
				transform: scale(1.1);
			}
		}
		&:before {
			position: absolute;
			left: 30px;
			top: 0;
			content: '';
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: transform @hover;
		}
		> span {
			display: table-cell;
			height: 60px;
			vertical-align: middle;
		}
        &.active a {
            color: #000000;
        }
	}
}

.news {
	margin: 0 0 -40px -30px;
	display: flex;
	flex-wrap: wrap;
	.resp(1050px,{
		margin: 0 0 -30px -20px;
	});
	.resp(767px,{
		margin: 0;
		display: block;
	});
	.news-box {
		padding-left: 30px;
		margin-bottom: 40px;
		width: 33.3333%;
		box-sizing: border-box;
		flex: 0 0 auto;
		.resp(1050px,{
			padding-left: 20px;
			margin-bottom: 30px;
			width: 50%;
		});
		.resp(767px,{
			width: auto;
			padding: 0;
		});
	}
}