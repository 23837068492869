.audit-about {
	&.icon-list	{
		padding-bottom: 54px;
	}
	> li {
		.respMin(768px,{
			width: 33.3333%;
		});
		&:nth-child(1){
			> .icon-list-header {
				&:before {
					width: 56px;
					height: 63px;
					background-image: url('../img/audit-about-1.svg');
				}
			}
		}
		&:nth-child(2){
			> .icon-list-header {
				&:before {
					width: 62px;
					height: 59px;
					background-image: url('../img/audit-about-2.svg');
				}
			}
		}
		&:nth-child(3){
			> .icon-list-header {
				&:before {
					width: 59px;
					height: 61px;
					background-image: url('../img/audit-about-3.svg');
				}
			}
		}
	}
}