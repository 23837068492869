.breadcrumbs {
    padding: 20px 0;
    border-top: 1px solid #EAEAEA;
    .resp(767px,{
        padding: 14px 0;
    });
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        > li {
            color: #BBB;
            font-size: 20px;
            line-height: 30px;
            padding-left: 50px;
            position: relative;
            .resp(767px,{
                padding-left: 40px;
                font-size: 16px;
                line-height: 20px;
            });
            &:first-child {
                padding: 0;
                &:before {
                    display: none;
                }
            }
            &:before {
                width: 50px;
                position: absolute;
                left: 0;
                top: 0;
                text-align: center;
                content: '/';
                .resp(767px,{
                    width: 40px;
                });
            }
        }
    }
}