/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  //display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    will-change: transform;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.owl-carousel .fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 300ms ease-in-out; }


.owl-nav {
    margin-top: 40px;
    display: flex;
    align-items: center;
    .resp(767px,{
      margin-top: 30px;
    });
    &:after {
      margin-left: 30px;
      height: 1px;
      background: @link;
      flex: 1 1 auto;
      display: block;
      content: '';
    }
    .owl-next,
    .owl-prev {
      .button;
      .button-reverse;
      width: 70px;
      height: 50px;
      padding: 0;
      flex: 0 0 auto;
      position: relative;
      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 9px;
        height: 21px;
        content: '';
        transform: translateX(-50%) translateY(-50%);
        transition: transform @hover;
      }
    }
    .owl-next {
      border-radius: 0 3px 3px 0;
      &:hover {
        &:before {
          transform: translateX(-30%) translateY(-50%);
        }
      }
      &:before {
        background: url('../img/next.svg') no-repeat;
        background-size: 100% 100%;
      }
    }
    .owl-prev {
      margin-right: 4px;
      border-radius: 3px 0 0 3px;
      &:hover {
        &:before {
          transform: translateX(-70%) translateY(-50%);
        }
      }
      &:before {
        background: url('../img/prev.svg') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
