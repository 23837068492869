.social {
	margin: 0 -10px -10px -10px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(767px,{
		flex: 0 0 auto;
		justify-content: center;
	});
	> li {
		margin: 0 5px;
		flex: 0 0 auto;
		> a {
			width: 50px;
			height: 50px;
			display: block;
			background: @link;
			position: relative;
			transition: background @hover;
			border-radius: 50%;
			&:before {
				position: absolute;
				left: 50%;
				top: 50%;
				content: '';
				transform: translateX(-50%) translateY(-50%);
			}
			&:hover {
				background: @linkhover;
			}
		}
	}
	.fb {
		&:before {
			width: 12px;
			height: 26px;
			background: url('../img/fb.svg') no-repeat;
			background-size: 100% 100%;
		}
	}
	.in {
		&:before {
			width: 18px;
			height: 18px;
			background: url('../img/in.svg') no-repeat;
			background-size: 100% 100%;
		}
	}
	.tg {
		&:before {
			width: 21px;
			height: 19px;
			background: url('../img/tg.svg') no-repeat;
			background-size: 100% 100%;
		}
	}
	.vk {
		&:before {
			width: 26px;
			height: 15px;
			background: url('../img/vk.svg') no-repeat;
			background-size: 100% 100%;
		}
	}
	.yt {
		&:before {
			width: 22px;
			height: 15px;
			background: url('../img/yt.svg') no-repeat;
			background-size: 100% 100%;
		}
	}
}