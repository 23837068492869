html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    position: relative;
    font-size: 18px;
	line-height: 28px;
	font-family: @font;
	color: @text;
	-webkit-text-size-adjust: 100%;
	min-width: 320px;
	margin: 0;
    padding: 0;
    min-height: 100%;
    padding-top: 90px;
    transition: padding @hover;
    .resp(767px,{
		font-size: 16px;
		line-height: 24px;
	});
    &.homepage {
    	.resp(1050px,{
			padding-top: 0;
		});
    }
    &.locked {
    	overflow: hidden;
    }
    &.scrolled {
    	padding-top: 80px;
    }
}

@font-face {
    font-family: 'ALSRubl-Tahoma';
    src: url('../fonts/alsrubl-tahoma-regular.eot');
    src: url('../fonts/alsrubl-tahoma-regular.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/alsrubl-tahoma-regular.woff') format('woff'),
	     url('../fonts/alsrubl-tahoma-regular.ttf') format('truetype'),
	     url('../fonts/alsrubl-tahoma-regular.svg#ALSRublArialRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

img {
	border: 0;
	max-width: 100%;
}

form,
fieldset {
	margin: 0;
	padding: 0;
	border: 0 none;
}

a {
	outline: 0;
	color: @link;
	text-decoration: none;
	transition: color @hover;
	&[href^="tel"] {
		color: @text;
	}
	&:hover {
		color: @linkhover;
	}

}

h1 {
	font-weight: bold;
	font-size: 64px;
	line-height: 78px;
	margin: 0 0 33px 0;
	color: #FFF;
	.resp(1050px,{
		font-size: 42px;
		line-height: 50px;
		margin-bottom: 30px;
	});
	.resp(767px,{
		font-size: 30px;
		line-height: 36px;
	});
}

h2 {
	font-weight: bold;
	font-size: 42px;
	line-height: 46px;
	margin: 0 0 42px 0;
	.resp(1279px,{
		font-size: 38px;
		line-height: 42px;
	});
	.resp(1100px,{
		font-size: 36px;
		line-height: 40px;
	});
	.resp(1050px,{
		font-size: 32px;
		line-height: 38px;
		text-align: center;
	});
	.resp(767px,{
		font-size: 24px;
		line-height: 29px;
	});
	& + .section-description {
		margin: -20px 0 33px 0;
		.resp(1050px,{
			margin: -20px auto 37px;
		});
		.resp(767px,{
			margin: -25px auto 30px;
		});
	}
	> span {
		color: @link;
	}
}

h3 {
	font-size: 38px;
	line-height: 42px;
	font-weight: bold;
	margin: 0 0 26px 0;
	.resp(767px,{
		margin: 0 0 20px 0;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
	});	
}

h4 {
	font-size: 24px;
	line-height: 32px;
	font-weight: bold;
	margin: 0 0 22px 0;
	.resp(767px,{
		font-size: 20px;
		line-height: 28px;
	});	
}

p {
	margin: 0 0 20px 0;
}

section {
	padding: 80px 0 100px 0;
	.resp(1279px,{
		padding: 60px 0 70px 0;
	});
	.resp(767px,{
		padding: 46px 0 60px 0;
	});
}

.bg	{
	background: @bg;
}

.center {
	text-align: center;
}

.clear {
	clear: both;
	height: 0;
	overflow: hidden;
	font-size: 0;
	line-height: 0;
}

.clearfix {
	&:before,
	&:after {
		display: table;
		line-height: 0;
		content: '';
	}
	&:after {
		clear: both;
	}
}

.container {
	max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    .resp(1050px,{
		padding: 0 20px;
	});
}

.cover {
	background-size: cover;
	background-position: center center;
}

.section-description {
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 7px;
	.resp(1050px,{
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		max-width: 460px;
		margin: 0 auto 16px;
	});
	.resp(1050px,{
		font-size: 18px;
	});
}

.set-mode {
	z-index: 1920;
	position: relative;
	display: none;
	.resp(1050px,{
		z-index: 1050;
	});
	.resp(767px,{
		z-index: 767;
	});
}

.simple-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	> li {
		margin-top: 20px;
		&:first-child {
			margin: 0;
		}
	}
}