.video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    border-radius: 5px;
    box-shadow: 0 10px 21px fade(#1177c7,23%);
    cursor: pointer;
    &.video-loaded {
        &:after,
        &:before,
        > span {
            opacity: 0;
            visibility: hidden;
        }
    }
    &:hover {
        &:after {
            transform: translateX(-50%) translateY(-50%) scale(0.95);
        }
        &:before {
            opacity: 0;
        }
    }
    &:after {
        width: 70px;
        height: 70px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        content: '';
        transform: translateX(-50%) translateY(-50%);
        background: #FFF url('../img/play.svg') no-repeat 31px center;
        background-size: 15px 24px;
        border-radius: 50%;
        transition: all @hover;
    }
    &:before {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        background: #061838;
        opacity: 0.48;
        transition: all @hover;
        z-index: 1;
    }
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    > span {
        position: absolute;
        right: 0;
        bottom: 20px;
        z-index: 3;
        background: @linkhover;
        padding: 0 15px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        color: #FFF;
        transition: all @hover;
    }
}