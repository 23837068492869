.cases {
	.cases-item {
		display: flex;
		align-items: stretch;
		margin-left: -30px;
		.resp(1279px,{
			margin-left: -15px;
		});
		.resp(767px,{
			display: block;
			margin: 0;
		});
		.cases-about {
			padding-left: 30px;
			box-sizing: border-box;
			width: 50%;
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			.resp(1279px,{
				padding-left: 15px;
			});
			.resp(1050px,{
				padding-left: 0;
			});
			.resp(767px,{
				width: auto;
				display: block;
			});
			.cases-about-box {
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 30px 0;
				.resp(1050px,{
					font-size: 16px;
					line-height: 24px;
				});
				.resp(767px,{
					flex: 0 0 auto;
					display: block;
					padding: 2px 0 0 0;
					text-align: center;
				});
				> p {
					flex: 0 0 auto;
					.resp(767px,{
						display: none;
					});
					&:last-child {
						margin-bottom: 0;
					}
				}
				.cases-about-category {
					color: @linkhover;
					text-transform: uppercase;
					font-weight: bold;
					margin-bottom: 10px;
					font-size: 18px;
				}
				.cases-about-header {
					font-size: 26px;
					line-height: 32px;
					font-weight: bold;
					margin-bottom: 21px;
					.resp(767px,{
						font-size: 20px;
						line-height: 26px;
						margin-bottom: 15px;
					});
				}
			}
			.cases-about-button-icons {
				display: flex;
				align-items: center;
				padding-bottom: 11px;
				.resp(767px,{
					display: block;
					padding: 0;
					text-align: center;
				});
				.cases-about-button {
					flex: 0 0 auto;
					margin-right: 15px;
					.resp(1279px,{
						margin: 0;
					});
					.resp(767px,{
						max-width: 360px;
						margin: 0 auto 25px;
					});
					.button {
						padding: 0 32px;
						height: 50px;
						line-height: 50px;
						font-size: 20px;
						.resp(1279px,{
							padding: 0 22px;
						});
						.resp(1050px,{
							font-size: 18px;
							line-height: 50px;
							height: 50px;
						});
						.resp(767px,{
							width: 100%;
							padding: 0;
							display: block;
						});
					}
				}
				.cases-icon-google {
					width: 114px;
					height: 38px;
					background: url('../img/google.png') no-repeat;
					.resp(1050px,{
						width: 86px;
						height: 28px;
						background-size: 100% 100%;
					});
				}
				.cases-icon-yandex {
					width: 62px;
					height: 42px;
					background: url('../img/yandex.png') no-repeat;
					.resp(1050px,{
						width: 47px;
						height: 32px;
						background-size: 100% 100%;
					});
				}
				.cases-icon-google,
				.cases-icon-yandex {
					flex: 0 0 auto;
					margin-left: 40px;
					.resp(1279px,{
						margin-left: 20px;
					});
					.resp(767px,{
						display: inline-block;
						vertical-align: middle;
						margin: 0 10px;
					});
				}
			}
		}
		.cases-image-stats {
			box-sizing: border-box;
			width: 50%;
			flex: 0 0 auto;
			padding: 0 30px 78px 51px;
			position: relative;
			.resp(1050px,{
				padding: 0 20px 50px 20px;
			});
			.resp(767px,{
				padding: 0 10px 140px 10px;
				width: auto;
				margin-bottom: 20px;
			});
			.cases-image {
				width: 100%;
				display: block;
				box-shadow: 0 10px 21px fade(#cad0d3,36%);
				border-radius: 3px;
				.resp(1050px,{
					box-shadow: 0 5px 10px fade(#cad0d3,36%);
				});
			}
			.cases-stats {
				position: absolute;
				bottom: 11px;
				right: 60px;
				box-sizing: border-box;
				padding: 16px;
				border: 4px solid @linkhover;
				border-radius: 3px;
				width: 290px;
				.resp(1050px,{
					right: auto;
					left: 50%;
					margin-left: -145px;
				});
				.resp(767px,{
					max-width: 380px;
					margin: 0;
					transform: translateX(-50%);
					bottom: 0;
				});
				> ul {
					margin: 0;
					padding: 0;
					list-style-type: none;
					> li {
						display: flex;
						justify-content: space-between;
						padding: 15px 20px;
						background: @link;
						font-size: 16px;
						line-height: 20px;
						margin-bottom: 10px;
						color: #FFF;
						> span {
							flex: 0 0 auto;
							display: block;
						}
					}
				}
				.cases-stats-result {
					margin: 0;
					background: @linkhover;
					color: @text;
				}
			}
		}
	}
}