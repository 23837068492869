.show-more {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .resp(420px,{
        display: block;
    });
    &:after {
        flex: 1 1 auto;
        display: block;
        height: 1px;
        content: '';
        background: @link;
        .resp(420px,{
            display: none;
        });
    }
    .button {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        padding: 0 23px;
        margin-right: 30px;
        flex: 0 0 auto;
        .resp(420px,{
           margin: 0;
           width: 100%;
           display: block;
           padding: 0;
        });
    }
}