.video-types {
	margin: 0 0 -30px -30px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(767px,{
		display: block;
		margin-left: 0;
	});
	> li {
		padding-left: 30px;
		margin-bottom: 30px;
		box-sizing: border-box;
		flex: 0 0 auto;
		display: flex;
		align-items: stretch;
		width: 33.3333%;
		.resp(767px,{
			padding: 0;
			width: auto;
			text-align: center;
			max-width: 380px;
			margin-left: auto;
			margin-right: auto;
		});
	}
}

.video-types-box {
	flex: 1 1 auto;
	border-radius: 5px;
	background: #FFF;
	padding: 25px;
	display: flex;
	transition: box-shadow @hover;
	.resp(1279px,{
		display: block;
	});
	&:hover {
		box-shadow: 0 5px 10px fade(#989898,22);
	}
	.video-types-image {
		width: 142px;
		height: 164px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		flex: 0 0 auto;
		margin-right: 40px;
		.resp(1279px,{
			margin: 0 auto;
		});
		&.video-types-in-display {
			background-image: url('../img/video-types-in-display.svg');
		}
		&.video-types-overlay {
			background-image: url('../img/video-types-overlay.svg');
		}
		&.video-types-preroll {
			background-image: url('../img/video-types-preroll.svg');
		}
	}
	.video-types-info {
		flex: 1 1 auto;
		padding-top: 18px;
		.resp(1279px,{
			text-align: center;
		});
		.video-types-header {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
			.resp(1279px,{
				margin-bottom: 10px;
			});
		}
		.video-types-text {
			font-size: 16px;
			line-height: 24px;
			.resp(767px,{
				max-width: 200px;
				margin: 0 auto;
			});
		}
	}
}