input[type="checkbox"] {
	position: absolute;
	left: -10000px;
	&:checked {
		& + label {
			&:after {
				opacity: 1;
			}
			&:before {
				background: @linkhover;
			}
		}
	}
	& + label {
		display: inline-block;
		font-size: 16px;
		line-height: 20px;
		padding: 5px 0 5px 42px;
		position: relative;
		cursor: pointer;
		vertical-align: top;
		.parsley-error & {
	        color: @linkhover;
	    }
		&:after {
			background: url('../img/checkbox.svg') no-repeat center center;
			background-size: 18px 15px;
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			z-index: 2;
			width: 30px;
			height: 30px;
			opacity: 0;
			transition: opacity @hover;
		}
		&:before {
			width: 30px;
			height: 30px;
			box-sizing: border-box;
			border-radius: 5px;
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			background: #FFF;
			box-shadow: 0 10px 21px fade(#98c2d3,36%);
			z-index: 1;
			transition: background @hover;
		}
	}
}