.video-advantages {
	> li {
		.respMin(768px,{
			width: 33.3333%;
		});
		&:nth-child(1){
			> .icon-list-header {
				&:before {
					width: 68px;
					height: 71px;
					background-image: url('../img/video-advantages-1.svg');
				}
			}
		}
		&:nth-child(2){
			> .icon-list-header {
				&:before {
					width: 62px;
					height: 60px;
					background-image: url('../img/video-advantages-2.svg');
				}
			}
		}
		&:nth-child(3){
			> .icon-list-header {
				&:before {
					width: 72px;
					height: 64px;
					background-image: url('../img/video-advantages-3.svg');
				}
			}
		}
	}
}