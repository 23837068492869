.subscribe {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	.resp(767px,{
		display: block;	
	});
}

.subscribe-content {
	flex: 1 1 auto;
	max-width: 703px;
	.resp(767px,{
		max-width: none;
		padding: 30px 44px 30px 30px;
		text-align: center;
		background-image: url('../img/page-header/subscribe.jpg');
		.cover;
		margin: 0 -20px 20px -20px;
	});
	.resp(767px,{
		padding: 30px 20px;
	});
	> h1 {
		font-size: 48px;
		line-height: 60px;
		margin-bottom: 52px;
		.resp(1279px,{
			font-size: 36px;
			line-height: 45px;
			margin-bottom: 32px;
		});
		.resp(999px,{
			display: none;
		});
		.resp(767px,{
			display: block;
			font-size: 28px;
			line-height: 35px;
		});
		.resp(479px,{
			font-size: 20px;
			line-height: 25px;
			margin-bottom: 30px;
		});
	}
	.subscribe-content-form-text {
		font-size: 18px;
		line-height: 27px;
		color: #FFF;
		display: none;
		margin-bottom: 21px;
		.resp(999px,{
			display: block;
		});
		.resp(767px,{
			display: none;
		});
	}
}

.subscribe-h1-tablet {
	margin-bottom: 40px;
	text-align: center;
	color: #FFF;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
	display: none;
	.resp(999px,{
		display: none;
	});
	.resp(767px,{
		display: none;
	});
}

.subscribe-form {
	flex: 0 0 416px;
	max-width: 416px;
	background: @bg;
	border-radius: 8px;
	padding: 30px;
	margin-left: 54px;
	box-sizing: border-box;
	.resp(999px,{
		flex: 0 0 354px;
		max-width: 354px;
		padding: 20px;
		margin-left: 24px;
	});
	.resp(767px,{
		max-width: none;
		margin: 0;
	});
	.form {
		display: block;
		border: 0;
		background: none;
		box-shadow: none;
		overflow: visible;
		> fieldset {
			max-width: none;
			margin: 0;
			padding: 0;
			position: relative;
			width: auto;
			&:nth-child(2) {
				border: 0;
			}
			&:last-child {
				width: auto;
			}
			& + fieldset {
				margin-top: 20px;
			}
			> label {
				position: absolute;
				left: 17px;
				top: 10px;
			    font-size: 15px;
		    	line-height: 19px;
			}
		}
		.button {
			font-size: 20px;
			border-radius: 8px;
			display: block;
			box-shadow: 0px 5px 10px rgba(249, 105, 72, 0.4);
		}
		.textfield {
			background: #FFF;
		    padding: 36px 17px 10px 17px;
		    height: 70px;
		    box-shadow: 0px 5px 10px rgba(152, 194, 211, 0.36);
		    border-radius: 8px;
		    .placeholder(@text);
		    transition: box-shadow @hover;
		    &:focus {
		    	box-shadow: none;
		    }
		}
	}
	.subscribe-form-header {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 22px;
		.resp(999px,{
			display: none;
		});
		.resp(767px,{
			display: block;
		});
		.resp(479px,{
          	font-size: 18px;
          	line-height: 27px;
          	margin-bottom: 14px;
        });
	}
	.subscribe-form-info {
		margin: 36px -30px -30px -30px;
		border-top: 2px solid #FFF;
		padding: 23px 30px 30px 56px;
		position: relative;
		font-size: 16px;
		line-height: 24px;
		.resp(999px,{
			font-size: 14px;
			line-height: 21px;
			margin: 20px -20px -20px -20px;
			padding: 13px 20px 20px 46px;
		});
		&:before {
			width: 25px;
			height: 29px;
			position: absolute;
			top: 23px;
			left: 26px;
			content: '';
			background: url('../img/shield.svg') no-repeat;
			background-size: 100% 100%;
			.resp(999px,{
				left: 16px;
				top: 15px;
			});
		}
		> span {
			.resp(767px,{
				display: block;
				max-width: 320px;
			});
		}
	}
}

.subscribe-wrapper {
	padding: 100px 0;
	background-image: url('../img/page-header/subscribe.jpg');
	.resp(1279px,{
		padding: 50px 0;
	});
	.resp(999px,{
		padding: 30px 0 60px 0;
	});
	.resp(767px,{
		padding: 0;
		background: none;
	});
}