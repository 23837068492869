.icon-list {
	margin: 0 0 -30px -30px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	.resp(767px,{
		display: block;
		margin-left: 0;
	});
	> li {
		padding-left: 30px;
		margin-bottom: 30px;
		box-sizing: border-box;
		flex: 0 0 auto;
		.resp(767px,{
			padding: 0;
			width: auto;
			text-align: center;
		});
	}
	.icon-list-header {
		min-height: 64px;
		display: flex;
		align-items: center;
		position: relative;
		font-size: 24px;
		line-height: 32px;
		padding-left: 80px;
		.resp(1050px,{
			padding: 67px 0 0 0;
			min-height: 0;
			display: block;
			text-align: center;
		});
		&:hover {
			&:before {
				transform: translateY(-50%) scale(1.1);
				.resp(1050px,{
					transform: translateX(-50%) scale(1.1);
				});
			}
		}
		&:after {
			content: '';
			min-height: inherit;
			font-size: 0;
			flex: 0 0 auto;
			width: 0;
			display: block;
		}
		&:before {
			position: absolute;
			left: 0;
			top: 50%;
			content: '';
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transform: translateY(-50%);
			transition: transform @hover;
			.resp(1050px,{
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			});
		}
		> span {
			display: block;
			max-width: 100%;
		}
	}
	.icon-list-text {
		font-size: 16px;
		line-height: 24px;
		margin-top: 21px;
		max-width: 360px;
		.resp(1050px,{
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		});
		> p {
			margin: 10px 0 0 0;
			&:first-child {
				margin: 0;
			}
		}
	}
}