.special-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	> li {
		font-size: 18px;
		line-height: 28px;
		padding-left: 31px;
		position: relative;
		margin-top: 20px;
		.resp(767px,{
			font-size: 16px;
			line-height: 24px;
		});
		&:first-child {
			margin: 0;
		}
		&:before {
			position: absolute;
			left: 0;
			top: 4px;
			width: 20px;
			height: 20px;
			content: '';
			border-radius: 50%;
			background: @link url('../img/checkbox.svg') no-repeat center center;
			background-size: 10px 8px;
			box-shadow: 0 5px 10px fade(@link,25%);
		}
	}
}