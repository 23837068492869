.check-list {
	margin: 0 0 0 -30px;
	padding: 0 0 20px 0;
	list-style-type: none;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	> li {
		padding-left: 30px;
		margin-bottom: 30px;
		box-sizing: border-box;
		flex: 0 0 auto;
		width: 33.3333%;
		.resp(1050px,{
			width: 50%;
		});
		.resp(767px,{
			width: 100%;
		});
	}
	.check-list-text {
		padding-left: 75px;
		font-size: 20px;
		line-height: 28px;
		position: relative;
		display: flex;
		align-items: center;
		min-height: 60px;
		.resp(767px,{
			font-size: 16px;
			line-height: 24px;
		});
		&:after {
			content:'';
			min-height: inherit;
			font-size: 0;
			flex: 0 0 auto;
			width: 0;
			display: block;
		}
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			width: 60px;
			height: 60px;
			background: url('../img/check.svg') no-repeat;
			background-size: 100% 100%;
		}
		> span {
			display: block;
			max-width: 100%;
		}
	}
}