.intro {
	position: relative;
	z-index: 1;
	background: url('../img/intro.jpg') no-repeat center top;
	padding: 0;
	.resp(1200px,{
		background: url('../img/intro-tablet.jpg') no-repeat right top;
	});
	.resp(767px,{
		background: url('../img/intro-mobile.jpg') no-repeat center 248px;
	});
	.container {
		height: 753px;
		box-sizing: border-box;
		padding-top: 44px;
		.resp(1200px,{
			height: auto;
			min-height: 423px;
			padding-bottom: 40px;
		});
		.resp(1050px,{
			padding-top: 83px;
			padding-bottom: 60px;
		});
		.resp(767px,{
			text-align: center;
		});
	}
	h1 {
		padding-bottom: 37px;
		position: relative;
		color: @text;
		.resp(1050px,{
			padding-bottom: 33px;
			max-width: 440px;
		});
		.resp(767px,{
			max-width: 300px;
			text-align: center;
			height: 341px;
			margin: 0 auto;
		});
		&:after {
			width: 80px;
			height: 3px;
			position: absolute;
			left: -40px;
			bottom: 0;
			content: '';
			background: @link;
			.resp(1050px,{
				left: 0;
			});
			.resp(767px,{
				display: none;
			});
		}
		> span {
			color: @linkhover;
			display: block;
		}
	}
	.button {
		.resp(420px,{
			width: 100%;
			display: block;
			padding: 0;
		});
	}
	.intro-content {
		max-width: 640px;
		.resp(1050px,{
			max-width: none;
		});
	}
	.intro-text {
		font-size: 26px;
		line-height: 32px;
		padding-bottom: 20px;
		.resp(1050px,{
			font-size: 20px;
			line-height: 24px;
			padding-top: 7px;
		});
		.resp(767px,{
			line-height: 28px;
		});
		> p {
			margin-bottom: 18px;
			.resp(767px,{
				margin-bottom: 20px;
			});
			> span {
				color: @linkhover;
			}
		}
	}
}