.audit-form {
	border: 6px solid @link;
	border-radius: 5px;
	background: @bg;
	padding: 20px 40px 40px 40px;
	.resp(1279px,{
		padding: 20px 25px 33px 25px;
	});
	.resp(767px,{
		padding: 20px;
	});
	.audit-form-info {
		display: flex;
		.resp(767px,{
			display: block;
		});
	}
	.audit-form-options {
		flex: 1 1 auto;
		padding-top: 6px;
		.resp(767px,{
			flex: 0 0 auto;
			margin-top: 15px;
			padding: 0;
		});
	}
	.audit-form-price {
		width: 370px;
		flex: 0 0 auto;
		font-size: 20px;
		line-height: 26px;
		.resp(1279px,{
			margin-right: 40px;
			width: auto;
		});
		.resp(767px,{
			text-align: center;
			margin: 0;
		});
		> strong {
			font-size: 64px;
			line-height: 72px;
			display: block;
			.resp(767px,{
				font-size: 42px;
				line-height: 52px;
			});
			> span {
				font-family: 'ALSRubl-Tahoma';
				font-size: 56px;
				.resp(767px,{
					font-size: 36px;
				});
			}
		}
	}
	.form {
		box-shadow: 0 5px 10px fade(#98c2d3,36%);
		margin-top: 20px;
		.resp(767px,{
			margin-top: 30px;
		});
		> fieldset {
			.resp(767px,{
				box-shadow: 0 5px 10px fade(#98c2d3,36%);
			});
			&:last-child {
				.resp(767px,{
					box-shadow: 0 5px 10px fade(#98c2d3,36%);
				});
			}
		}
	}
}