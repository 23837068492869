.contact-info {
	> ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		> li {
			margin-top: 6px;
			.resp(767px,{
				margin-top: 10px;
			});
			&:first-child {
				margin: 0;
			}
			> a {
				display: inline-block;
				vertical-align: top;
				padding-left: 32px;
				position: relative;
				&:hover {
					&:before {
						animation-name: hvr-pop;
						animation-duration: 0.3s;
						animation-timing-function: linear;
						animation-iteration-count: 1;
					}
				}
				&:before {
					position: absolute;
					top: 50%;
					content: '';
					transform: translateY(-50%);
				}
			}
		}
		.contact-info-mail {
			&:before {
				left: 1px;
				width: 18px;
				height: 13px;
				background: url('../img/mail.svg') no-repeat;
				background-size: 100% 100%;
			}
		}
		.contact-info-skype {
			&[href^="tel"] {
			    color: @link;
			    &:hover {
			    	color: @linkhover;
			    }
			}
			&:before {
				left: 0;
				width: 20px;
				height: 20px;
				background: url('../img/skype.svg') no-repeat;
				background-size: 100% 100%;
			}
		}
	}
	.contact-info-phone {
		font-size: 38px;
		line-height: 42px;
		font-weight: bold;
		margin: 0 -15px 26px 0;
		.resp(767px,{
			margin: 0 -10px 24px -10px;
		});
	}
}