.contact {
	background: @bg;
	border-radius: 5px;
	padding: 40px;
	margin-bottom: 47px;
	border: 6px solid @link;
	.resp(1279px,{
		padding: 30px;
	});
	.resp(767px,{
		padding: 20px;
	});
	.form {
		.resp(767px,{
			box-shadow: none;
			background: none;
		});
		&:first-child {
			margin-bottom: 20px;
			.resp(767px,{
				margin: 0 auto;
			});
			> fieldset {
				&:last-child,
				&:first-child {
					margin: 10px 0;
					padding: 0 20px;
					width: 100%;
					flex: 0 0 auto;
					box-sizing: border-box;
					background: #FFF;
					.resp(1050px,{
						flex: 0 0 auto;
					});
					.resp(767px,{
						border-radius: 5px;
						box-shadow: 0 5px 10px fade(#98c2d3,36%);
						padding: 10px 20px;
						flex: 0 0 auto;
						max-width: none;
						margin: 0 0 10px 0;
					});
				}
			}
		}
		box-shadow: 0 5px 10px fade(#98c2d3,36%);
		> fieldset {
			.resp(767px,{
				box-shadow: 0 5px 10px fade(#98c2d3,36%);
			});
			&:last-child {
				.resp(767px,{
					box-shadow: 0 5px 10px fade(#98c2d3,36%);
				});
			}
		}
	}
}

.contact-row {
	margin-left: -30px;
	display: flex;
	.resp(767px,{
		margin: 0;
		display: block;
	});
	.contact-row-info {
		padding-left: 30px;
		width: 33.3333%;
		box-sizing: border-box;
		flex: 0 0 auto;
		.resp(1279px,{
			width: 50%;
		});
		.resp(767px,{
			width: auto;
			padding: 0;
			margin-bottom: 40px;
			text-align: center;
		});
	}
	.contact-row-social {
		padding-left: 30px;
		width: 66.6666%;
		box-sizing: border-box;
		flex: 0 0 auto;
		.resp(1279px,{
			width: 50%;
		});
		.resp(767px,{
			width: auto;
			padding: 0;
		});
	}
}