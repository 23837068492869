.education-goals {
	margin: 0 0 -30px -30px;
	padding: 0 0 80px 0;
	list-style-type: none;
	display: flex;
	align-items: stretch;
	.resp(900px,{
		display: block;
		margin: 0 0 -20px 0;
		padding-bottom: 60px;
	});
	& + h2 {
		max-width: 1040px;
	}
	> li {
		padding-left: 30px;
		margin-bottom: 30px;
		box-sizing: border-box;
		flex: 0 0 auto;
		width: 50%;
		display: flex;
		align-items: stretch;
		.resp(900px,{
			padding: 0;
			width: auto;
			margin-bottom: 20px;
		});
		&:nth-child(1){
			> .education-goals-box {
				.education-goals-header {
					&:before {
						width: 66px;
						height: 61px;
						background-image: url('../img/education-goals-1.svg');
					}
				}
			}
		}
		&:nth-child(2){
			> .education-goals-box {
				.education-goals-header {
					&:before {
						width: 67px;
						height: 70px;
						background-image: url('../img/education-goals-2.svg');
					}
				}
			}
		}
	}
	.education-goals-box {
		border: 10px solid @bg;
		padding: 30px 40px;
		flex: 1 1 auto;
		transition: border-color @hover;
		.resp(900px,{
			padding: 20px 30px;
		});
		.resp(767px,{
			padding: 20px;
		});
		&:hover {
			border-color: @link;
		}
		.education-goals-header {
			min-height: 64px;
			display: flex;
			align-items: center;
			position: relative;
			font-size: 24px;
			line-height: 32px;
			padding-left: 80px;
			.resp(767px,{
				padding: 67px 0 0 0;
				min-height: 0;
				display: block;
				text-align: center;
			});
			&:before {
				position: absolute;
				left: 0;
				top: 50%;
				content: '';
				background-size: 100% 100%;
				background-repeat: no-repeat;
				transform: translateY(-50%);
				.resp(767px,{
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				});
			}
		}
		.education-goals-text {
			font-size: 18px;
			line-height: 28px;
			margin-top: 18px;
			.resp(767px,{
				font-size: 16px;
				line-height: 24px;
				text-align: center;
			});
			> p {
				margin: 20px 0 0 0;
				&:first-child {
					margin: 0;
				}
			}
		}
	}
}