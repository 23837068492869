.list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
        padding-left: 53px;
        position: relative;
        font-size: 20px;
        line-height: 30px;
        color: #FFF;
        .resp(999px,{
           font-size: 18px;
           line-height: 27px;
        });
        .resp(767px,{
            text-align: left;
        });
        .resp(479px,{
            font-size: 16px;
            line-height: 24px;
        });
        & + li {
            margin-top: 20px;
            .resp(479px,{
                margin-top: 23px;
            });
        }
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            background: #FFF url('../img/list-check.svg') no-repeat;
            background-size: 23px 19px;
            background-position: center center;
            content: '';
            border-radius: 50%;
            .resp(999px,{
               top: 1px;
            });
            .resp(479px,{
               top: 3px;
            });
        }
    }
}