.goal {
	border: 10px solid @link;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 30px 30px 30px 172px;
	position: relative;
	box-shadow: 0 10px 21px fade(#cad0d3,36%);
	min-height: 188px;
	box-sizing: border-box;
	.resp(1050px,{
		padding-left: 160px;
	});
	.resp(767px,{
		padding: 106px 25px 24px 25px;
		display: block;
		min-height: 0;
	});
	&:before {
		width: 172px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		content: '';
		background: url('../img/goal.svg') no-repeat center center;
		background-size: 88px 74px;
		.resp(1050px,{
			width: 160px;
		});
		.resp(767px,{
			display: block;
			min-height: 0;
			width: 100%;
			height: 106px;
		});
	}
	.goal-text {
		font-size: 24px;
		line-height: 36px;
		flex: 0 0 auto;
		.resp(767px,{
			font-size: 18px;
			line-height: 26px;
			text-align: center;
		});
	}
}