.page-nav {
    margin-top: 70px;
    display: flex;
    align-items: center;
    .resp(1050px,{
        margin-top: 50px;
    });
    .resp(767px,{
        margin-top: 40px;
        display: block;
        text-align: center;
    });
    &:after {
        flex: 1 1 auto;
        display: block;
        height: 1px;
        content: '';
        background: @link;
        order: 2;
        .resp(767px,{
            display: none;
        });
    }
    .page-nav-go {
        margin-left: 30px;
        flex: 0 0 auto;
        order: 3;
        .resp(767px,{
            margin: 0 auto;
            max-width: 326px;
        });
        .button {
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            padding: 0 23px;
            .resp(767px,{
                padding: 0;
                width: 100%;
                display: block;
            });
        }
    }
    .pager {
        flex: 0 0 auto;
        margin-right:30px;
        order: 1;
        .resp(767px,{
            display: flex;
            margin: 0 0 20px 0;
            justify-content: center;
        });
    }
}

.pager {
    > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        .resp(767px,{
            margin: 0 -10px;
        });
        > li {
            margin-left: 5px;
            &:first-child {
                margin: 0;
                > a,
                > span {
                    border-radius: 3px 0 0 3px;
                }
            }
            &:last-child {
                > a,
                > span {
                    border-radius: 0 3px 3px 0;
                }
            }
            > a {
                color: #000;
                background: @bg;
                transition: background @hover;
                &:hover {
                    background: darken(@bg,5%);
                    color: #000;
                }
            }
            > span {
                color: #FFF;
                background: @link;
                box-shadow: 0 5px 10px fade(@link,25%);
                cursor: default;
            }
            > a ,
            > span {
                height: 50px;
                width: 50px;
                display: block;
                line-height: 50px;
                text-align: center;
                font-size: 20px;
            }
        }
    }
}