.team {
	margin: 0 0 -30px -30px;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	.resp(1100px,{
		margin: 0 0 -20px -20px;
	});
	.resp(600px,{
		display: block;
		margin: 0 0 -20px 0;
	});
	> li {
		display: flex;
		align-items: stretch;
		flex: 0 0 auto;
		width: 50%;
		box-sizing: border-box;
		padding-left: 30px;
		margin-bottom: 30px;
		.resp(1100px,{
			padding-left: 20px;
			margin-bottom: 20px;
		});
		.resp(600px,{
			width: auto;
			padding-left: 0;
			max-width: 380px;
			margin: 0 auto 20px;
		});
	}
}

.team-box {
	border: 10px solid @bg;
	border-radius: 5px;
	padding: 30px;
	display: flex;
	flex: 1 1 auto;
	transition: border-color @hover;
	.resp(1100px,{
		padding: 20px;
	});
	.resp(960px,{
		text-align: center;
		flex-direction: column;
	});
	&:hover {
		border-color: @link;
	}
	.team-image {
		width: 200px;
		margin-right: 30px;
		flex: 0 0 auto;
		.resp(1100px,{
			margin-right: 20px;
			width: 160px;
		});
		.resp(960px,{
			width: 200px;
			margin: 0 auto 20px;
		});
		> img {
			width: 100%;
			display: block;
			border-radius: 1px;
		}
	}
	.team-info {
		flex: 1 1 auto;
		.resp(960px,{
			text-align: center;
			flex-direction: column;
			display: flex;
		});
		.team-name {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 15px;
			.resp(960px,{
				margin-bottom: 5px;
				flex: 0 0 auto;
			});
		}
		.team-position {
			position: relative;
			font-size: 20px;
			line-height: 28px;
			color: #666;
			margin-bottom: 30px;
			padding-bottom: 24px;
			.resp(960px,{
				padding-bottom: 20px;
				margin-bottom: 20px;
				flex: 1 1 auto;
			});
			&:after {
				width: 50px;
				height: 1px;
				position: absolute;
				left: 0;
				bottom: 0;
				content: '';
				background: @linkhover;
				.resp(960px,{
					left: 50%;
					margin-left: -25px;
				});
			}
		}
		.team-social {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			.resp(960px,{
				justify-content: center;
				flex: 0 0 auto;
			});
			> li {
				flex: 0 0 auto;
				margin-left: 5px;
				.resp(960px,{
					margin-left: 15px;
				});
				&:first-child {
					margin: 0;
				}
				> a {
					width: 30px;
					height: 30px;
					display: block;
					background: @link;
					position: relative;
					transition: background @hover;
					border-radius: 50%;
					&:before {
						position: absolute;
						left: 50%;
						top: 50%;
						content: '';
						transform: translateX(-50%) translateY(-50%);
					}
					&:hover {
						background: @linkhover;
					}
				}
			}
			.fb {
				&:before {
					width: 7px;
					height: 16px;
					background: url('../img/fb.svg') no-repeat;
					background-size: 100% 100%;
				}
			}
			.tg {
				&:before {
					width: 13px;
					height: 11px;
					background: url('../img/tg.svg') no-repeat;
					background-size: 100% 100%;
				}
			}
			.vk {
				&:before {
					width: 16px;
					height: 9px;
					background: url('../img/vk.svg') no-repeat;
					background-size: 100% 100%;
				}
			}
		}
	}
}