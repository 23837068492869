.popup {
	position: fixed;
	z-index: 2000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	box-sizing: border-box;
	padding: 10px;
	background: fade(@link,65%);
	transition: opacity @hover, visibility @hover;
	will-change: transform;
	&.opened {
		opacity: 1;
		visibility: visible;
	}
	.form {
		display: block;
		background: none;
		border-radius: 0;
		box-shadow: none;
		overflow: visible;
		> fieldset {
			background: #FFF;
			overflow: hidden;
			border-radius: 5px;
			box-shadow: 0 5px 10px fade(#124165,16%);
			padding: 10px 20px;
			flex: 0 0 auto;
			max-width: none;
			margin: 0 0 10px 0;
			width: 100%;
			&:nth-child(2) {
				border: 0;
			}
			&:last-child {
				background: none;
				border-radius: 5px;
				box-shadow: none;
				padding: 0;
				width: auto;
				margin: 0;
			}
		}
		.button {
			border-radius: 5px;
			display: block;
		}
	}
	.popup-box {
		background: @bg;
		border: 6px solid @link;
		border-radius: 5px;
		padding: 13px 20px 20px 20px;
		box-sizing: border-box;
		flex: 0 1 auto;
		overflow: auto;
		width: 100%;
		max-width: 400px;
		position: relative;
		.popup-close {
			width: 20px;
			height: 20px;
			position: absolute;
			right: 20px;
			top: 20px;
			margin: 0;
			outline: 0;
			padding: 0;
			cursor: pointer;
			border: 0;
			.svg('../img/close.svg',@linkhover);
			background-color: transparent;
			-webkit-appearance: none;
			-webkit-border-radius: 0;
			z-index: 10;
		}
	}
}