.settings-intro {
	min-height: 553px;
	box-sizing: border-box;
	background: url('../img/settings-intro.jpg') no-repeat right top;
	.resp(900px,{
		min-height: 0;
	});
	.resp(767px,{
		background: none;
	});
	.settings-intro-box {
		max-width: 690px;
		.resp(900px,{
			max-width: 364px;
		});
		.resp(767px,{
			max-width: none;
			text-align: center;
		});
		.resp(419px,{
			.button {
				width: 100%;
				padding: 0;
				display: block;
			}
		});
		> h2 {
			position: relative;
			padding-bottom: 50px;
			margin-bottom: 27px;
			.resp(1050px,{
				text-align: left;
				max-width: 540px;
				padding-bottom: 32px;
			});
			.resp(767px,{
				text-align: center;
				padding: 0;
				margin-bottom: 30px;
				max-width: none;
			});
			&:after {
				width: 80px;
				height: 3px;
				position: absolute;
				left: -40px;
				bottom: 0;
				content: '';
				background: @link;
				.resp(1050px,{
					left: 0;
				});
				.resp(767px,{
					display: none;
				});
			}
			> span {
				color: @linkhover;
			}
		}
		.settings-intro-image {
			display: none;
			.resp(767px,{
				width: 100%;
				display: block;
				max-width: 420px;
				margin: 0 auto;
			});
		}
		.settings-intro-text {
			font-size: 26px;
			line-height: 32px;
			padding-bottom: 20px;
			.resp(1050px,{
				font-size: 20px;
				line-height: 24px;
				padding-top: 7px;
			});
			.resp(900px,{
				max-width: 280px;
			});
			.resp(767px,{
				line-height: 28px;
				text-align: center;
				max-width: none;
				padding: 0;
			});
			> p {
				margin-bottom: 18px;
				.resp(767px,{
					margin-bottom: 20px;
					&:last-child {
						margin: 0;
					}
				});
				> span {
					color: @linkhover;
				}
			}
		}
	}
}