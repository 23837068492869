.education-about {
	> li {
		.respMin(1051px,{
			width: 33.3333%;
		});
		.respMin(768px,{
			width: 50%;
		});
		&:nth-child(1){
			> .icon-list-header {
				&:before {
					width: 63px;
					height: 63px;
					background-image: url('../img/education-about-1.svg');
				}
			}
		}
		&:nth-child(2){
			> .icon-list-header {
				&:before {
					width: 64px;
					height: 60px;
					background-image: url('../img/education-about-2.svg');
				}
			}
		}
		&:nth-child(3){
			> .icon-list-header {
				&:before {
					width: 68px;
					height: 66px;
					background-image: url('../img/education-about-3.svg');
				}
			}
		}
		&:nth-child(4){
			> .icon-list-header {
				&:before {
					width: 58px;
					height: 59px;
					background-image: url('../img/settings-why-google-3.svg');
				}
			}
		}
		&:nth-child(5){
			> .icon-list-header {
				&:before {
					width: 62px;
					height: 64px;
					background-image: url('../img/education-about-5.svg');
				}
			}
		}
		&:nth-child(6){
			> .icon-list-header {
				&:before {
					width: 63px;
					height: 58px;
					background-image: url('../img/education-about-6.svg');
				}
			}
		}
		&:nth-child(7){
			> .icon-list-header {
				&:before {
					width: 56px;
					height: 63px;
					background-image: url('../img/education-about-7.svg');
				}
			}
		}
		&:nth-child(8){
			> .icon-list-header {
				&:before {
					width: 55px;
					height: 71px;
					background-image: url('../img/education-about-8.svg');
				}
			}
		}
		&:nth-child(9){
			> .icon-list-header {
				&:before {
					width: 63px;
					height: 56px;
					background-image: url('../img/education-about-9.svg');
				}
			}
		}
	}
}

.education-about-button {
	max-width: 386px;
	margin: 0 auto;
	padding-top: 50px;
	.button {
		width: 100%;
		display: block;
		padding: 0;
	}
}