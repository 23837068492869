.education-form {
	background: @link;
	h2 {
		color: #FFF;
	}
	.education-form-info {
		display: flex;
		.resp(767px,{
			display: block;
		});
	}
	.education-form-options {
		flex: 1 1 auto;
		padding-top: 6px;
		.resp(767px,{
			flex: 0 0 auto;
			margin-top: 15px;
			padding: 0;
		});
		input[type="checkbox"] {
			& + label {
				&:before {
					box-shadow: 0 5px 10px fade(#124165,44%);
				}
				> a {
					color: #FFF;
				}
			}
		}
	}
	.education-form-price {
		width: 370px;
		flex: 0 0 auto;
		font-size: 20px;
		line-height: 26px;
		color: #FFF;
		.resp(1279px,{
			margin-right: 40px;
			width: auto;
		});
		.resp(767px,{
			text-align: center;
			margin: 0;
		});
		> strong {
			font-size: 64px;
			line-height: 72px;
			display: block;
			.resp(767px,{
				font-size: 42px;
				line-height: 52px;
			});
			> span {
				font-family: 'ALSRubl-Tahoma';
				font-size: 56px;
				.resp(767px,{
					font-size: 36px;
				});
			}
		}
	}
	.form {
		margin-top: 44px;
		.resp(1050px,{
			display: block;
			background: none;
			border-radius: 0;
			box-shadow: none;
			max-width: 480px;
			margin: 30px auto 0;
			overflow: visible;
		});
		.resp(767px,{
			max-width: 480px;
		});
		> fieldset {
			width: auto;
			flex: 1 1 20%;
			max-width: 20%;
			.resp(1050px,{
				background: #FFF;
				overflow: hidden;
				border-radius: 5px;
				box-shadow: 0 5px 10px fade(#124165,44%);
				padding: 10px 20px;
				flex: 0 0 auto;
				max-width: none;
				margin: 0 0 10px 0;
			});
			&:nth-child(3),
			&:nth-child(4) {
				border-left: 1px solid #C2C2C2;
				.resp(1050px,{
					border: 0;
				});
			}
			&:last-child {
				width: auto;
				flex: 1 1 25%;
				max-width: 20%;
				.resp(1050px,{
					background: none;
					border-radius: 5px;
					box-shadow: 0 5px 10px fade(#124165,44%);
					padding: 0;
					width: auto;
					margin: 0;
					max-width: none;
					flex: 0 0 auto;
				});
			}
		}
		.button {
			.resp(1050px,{
				border-radius: 5px;
				display: block;
				width: 100%;
				padding: 0;
			});
		}
	}
}