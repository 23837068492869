.case-stats {
	background: @bg;
	padding: 50px 0 60px 0;
	h4 {
		padding-left: 208px;
		.resp(1050px,{
			padding: 0;
			text-align: center;
		});
	}
	.case-stats-table {
		border-radius: 3px;
		background: #FFF;
		padding: 30px;
		box-shadow: 0 10px 21px fade(#cad0d3,36%);
		.resp(1050px,{
			padding: 30px 20px;
		});
		.resp(767px,{
			padding: 10px;
		});
		> table {
			width: 100%;
			border-collapse: collapse;
			table-layout: fixed;
			.resp(1100px,{
				table-layout: normal;
			});
			.resp(767px,{
				display: block;
				> tbody {
					display: block;
				}
			});
			td {
				font-weight: normal;
				text-align: right;
				line-height: 24px;
				padding: 12px;
				border-bottom: 2px solid @bg;
				.resp(1100px,{
					padding: 10px;
				});
				.resp(767px,{
					display: flex;
					justify-content: space-between;
					border: 0;
					padding: 2px 20px;
				});
				> i {
					display: none;
					font-style: normal;
					.resp(767px,{
						display: block;
						flex: 0 0 auto;
					});
				}
				> span {
					display: block;
					.resp(767px,{
						flex: 0 0 auto;
					});
				}
			}
			th {
				font-weight: normal;
				text-align: right;
				line-height: 24px;
				padding: 0 12px 12px 12px;
				border-bottom: 2px solid @link;
				white-space: nowrap;
				.resp(1100px,{
					padding: 0 10px 10px 10px;
					white-space: normal;
				});
			}
			tr {
				.resp(767px,{
					display: block;
					margin-bottom: 12px;
					&:first-child {
						display: none;
					}
					&:last-child {
						margin: 0;
						> td {
							&:first-child {
								background: @link;
								color: #FFF;
								> strong {
									font-weight: bold;
								}
							}
							strong {
								font-weight: normal;
							}
						}
					}
					> td {
						&:first-child {
							display: block;
							background: @bg;
							font-weight: bold;
							padding: 7px 20px;
							margin-bottom: 7px;
						}
					}
				});
				> td,
				> th {
					&:first-child {
						width: 30%;
						text-align: left;
						.resp(1100px,{
							width: auto;
						});
					}
				}
			}
		}
	}
}