.form {
	background: #FFF;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 5px 10px fade(#124165,44%);
	display: flex;
	.resp(767px,{
		display: block;
		background: none;
		border-radius: 0;
		box-shadow: none;
		max-width: 480px;
		margin: 0 auto;
		overflow: visible;
	});
	label {
		font-size: 16px;
		line-height: 20px;
		display: block;
	}
	> fieldset {
		width: 33%;
		flex: 0 0 auto;
		margin: 10px 0;
		padding: 0 20px;
		box-sizing: border-box;
		.resp(1050px,{
			width: auto;
			flex: 1 1 50%;
			max-width: 50%;
		});
		.resp(767px,{
			background: #FFF;
			overflow: hidden;
			border-radius: 5px;
			box-shadow: 0 5px 10px fade(#124165,44%);
			padding: 10px 20px;
			flex: 0 0 auto;
			max-width: none;
			margin: 0 0 10px 0;
		});
		&:nth-child(2) {
			border-left: 1px solid #C2C2C2;
			.resp(767px,{
				border: 0;
			});
		}
		&:last-child {
			width: 34.1%;
			padding: 0;
			margin: 0;
			.resp(1050px,{
				width: 212px;
				flex: 0 0 auto;
			});
			.resp(767px,{
				background: none;
				border-radius: 5px;
				box-shadow: 0 5px 10px fade(#124165,44%);
				padding: 0;
				width: auto;
				margin: 0;
			});
		}
	}
	.button {
		border-radius: 0 5px 5px 0;
		.resp(1279px,{
			font-size: 20px;
		});
		.resp(1050px,{
			font-size: 20px;
			line-height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			white-space: normal;
			text-align: center;
		});
		.resp(767px,{
			border-radius: 5px;
			display: block;
		});
	}
}