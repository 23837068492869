.case-solution {
	position: relative;
	border-top: 1px solid @bg;
	padding: 48px 0 52px 208px;
	.resp(1279px,{
		padding-left: 150px;
	});
	.resp(767px,{
		padding: 120px 0 35px 0;
	});
	&.case-solution-complexity {
		&:before {
			width: 68px;
			height: 67px;
			left: 36px;
			background: url('../img/case-solution-complexity.svg') no-repeat;
			background-size: 100% 100%;
			.resp(767px,{
				top: 29px;
				left: 50%;
			});
		}
	}
	&.case-solution-result {
		&:before {
			width: 73px;
			height: 70px;
			left: 30px;
			background: url('../img/education-about-2.svg') no-repeat;
			background-size: 100% 100%;
			.resp(767px,{
				top: 28px;
				left: 50%;
			});
		}
	}
	&.case-solution-strategy {
		&:before {
			width: 69px;
			height: 80px;
			left: 41px;
			background: url('../img/case-solution-strategy.svg') no-repeat;
			background-size: 100% 100%;
			.resp(767px,{
				top: 28px;
				left: 50%;
			});
		}
	}
	&:first-child {
		border: 0;
	}
	&:before {
		position: absolute;
		top: 48px;
		content: '';
		.resp(1050px,{
			transform: translateX(-20px);
		});
		.resp(767px,{
			transform: translateX(-50%);
		});
	}
	> p {
		&:last-child {
			margin: 0;
		}
	}
	.case-solution-description {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 22px;
		.resp(767px,{
			font-size: 20px;
		});
		> h4 {
			display: inline;
			margin: 0;
		}
	}
	.special-list {
		padding-bottom: 38px;
		& + h4 {
			margin-bottom: 32px;
		}
	}
}

.case-solution-result-info {
	border: 10px solid @link;
	border-radius: 3px;
	background: #FFF;
	padding: 20px 0;
	box-shadow: 0 10px 21px fade(#cad0d3,36%);
	display: flex;
	align-items: stretch;
	justify-content: stretch;
	margin-left: -208px;
	.resp(1279px,{
		margin-left: -150px;
		flex-wrap: wrap;
		padding: 20px;
	});
	.resp(767px,{
		margin: 0;
		display: block;
	});
	.case-solution-result-info-box {
		flex: 1;
		width: 0;
		box-sizing: border-box;
		padding: 18px 10px 24px 10px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		font-size: 18px;
		line-height: 24px;
		.resp(1050px,{
			flex: 1 1 50%;
			max-width: 50%;
		});
		.resp(767px,{
			flex: 0 0 auto;
			max-width: none;
			width: auto;
			padding: 24px 0;
			justify-content: flex-start;
		});
		&:nth-child(1) {
			.resp(1050px,{
				border-bottom: 1px solid @link;
				border-right: 1px solid @link;
			});
			.resp(767px,{
				padding-top: 0;
				border: 0;
			});
		}
		&:nth-child(2) {
			border-left: 1px solid @link;
			.resp(1050px,{
				border-bottom: 1px solid @link;
				border-left: 0;
			});
			.resp(767px,{
				border-top: 1px solid @link;
				border-bottom: 0;
			});
		}
		&:nth-child(3) {
			border-left: 1px solid @link;
			.resp(1050px,{
				border-right: 1px solid @link;
				border-left: 0;
			});
			.resp(767px,{
				border-top: 1px solid @link;
				border-right: 0;
			});
		}
		&:nth-child(4) {
			border-left: 1px solid @link;
			.resp(1050px,{
				border-left: 0;
			});
			.resp(767px,{
				padding-bottom: 0;
			});
		}
		.case-solution-result-info-title {
			flex: 0 0 auto;
			margin-right: 30px;
			.resp(1279px,{
				margin-right: 20px;
			});
			.resp(1050px,{
				margin-right: 30px;
			});
			.resp(767px,{
				width: 50%;
				margin: 0;
			});
			> span {
				font-size: 24px;
				line-height: 32px;
				display: block;
				margin-bottom: 1px;
				text-transform: uppercase;
				font-weight: bold;
			}
		}
		.case-solution-result-info-value {
			flex: 0 0 auto;
			.resp(767px,{
				width: 50%;
			});
			.resp(420px,{
				text-align: right;
			});
			> span {
				font-size: 42px;
				line-height: 46px;
				display: block;
				margin-bottom: 1px;
				font-weight: bold;
				color: @link;
			}
		}
	}
}

.case-solution-wrapper {
	padding: 0;
}