.resp(@maxWidth; @rules) {
    @media only screen and (max-width: @maxWidth) {
        @rules();
    }
}

.respMin(@minWidth; @rules) {
    @media only screen and (min-width: @minWidth) {
        @rules();
    }
}

.respHeight(@maxHeight; @rules) {
    @media only screen and (max-height: @maxHeight) {
        @rules();
    }
}

.retina(@rules) {
    @media only screen and @retina {
        @rules();
    }
}

.orientation(@orientation; @rules) {
    @media(orientation:@orientation) {
		@rules();
    }
}

.placeholder(@color) {
	&::-webkit-input-placeholder {
	    -khtml-opacity: 1;
	    opacity: 1;
	    color: @color;
	}
	&::-moz-placeholder {
	    -moz-opacity: 1;
	    opacity: 1;
	    color: @color;
	}
	.placeholder {
	    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
	    opacity: 1;
	    color: @color;
	}
}

.svg(@src, @fill-new) {
	@data-uri: data-uri('image/svg+xml;charset=UTF-8', "@{src}");
	@replace-default: escape('<path ');
	@replace-new: escape('<path fill="@{fill-new}" ');
	@replace-src: replace("@{data-uri}", @replace-default, @replace-new, "g");
	background-image: e(@replace-src);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}