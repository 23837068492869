.settings-why-yandex {
	background: @bg;
	.icon-list {
		> li {
			.respMin(768px,{
				width: 50%;
			});
			&:nth-child(1){
				> .icon-list-header {
					&:before {
						width: 61px;
						height: 59px;
						background-image: url('../img/settings-why-yandex-1.svg');
					}
				}
			}
			&:nth-child(2){
				> .icon-list-header {
					&:before {
						width: 60px;
						height: 69px;
						background-image: url('../img/settings-why-yandex-2.svg');
					}
				}
			}
			&:nth-child(3){
				> .icon-list-header {
					&:before {
						width: 59px;
						height: 65px;
						background-image: url('../img/settings-why-yandex-3.svg');
					}
				}
			}
			&:nth-child(4){
				> .icon-list-header {
					&:before {
						width: 68px;
						height: 57px;
						background-image: url('../img/goal.svg');
					}
				}
			}
		}
		.icon-list-header {
			min-height: 0;
			height: auto;
			display: block;
			padding-left: 87px;
			.resp(1050px,{
				padding: 0 0 0 80px;
				text-align: left;
			});
			.resp(767px,{
				padding: 67px 0 0 0;
				text-align: center;
			});
			&:before {
				top: 0;
				transform: none;
				.resp(1050px,{
					left: 0;
					transform: none;
				});
				.resp(767px,{
					left: 50%;
					transform: translateX(-50%);
				});
			}
		}
		.icon-list-text {
			margin-top: 10px;
			padding-left: 87px;
			max-width: none;
			.resp(1050px,{
				text-align: left;
				margin: 10px 0 0 0;
			});
			.resp(767px,{
				padding: 0;
				text-align: center;
				margin: 21px auto 0;
				max-width: 360px;
			});
		}
	}
}
