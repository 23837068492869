button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.button {
	display: inline-block;
	vertical-align: top;
	color: #FFF;
	font-size: 24px;
	line-height: 70px;
	font-weight: bold;
	text-align: center;
	height: 70px;
	font-family: @font;
	padding: 0 48px;
	box-shadow: 0 3px 8px fade(@link,44%);
	text-decoration: none;
	border-radius: 3px;
	-webkit-appearance: none;
	overflow: visible;
	white-space: nowrap;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
    cursor: pointer;
	border: 0;
	transition: background @hover, box-shadow @hover;
	background: @linkhover;
	&:hover {
		background: #F95048;
		color: #FFF;
		box-shadow: 0 1px 4px fade(@linkhover,44%);
	}
	&:active {
		background: #993530;
	}
	&.button-block {
		padding: 0 10px;
		width: 100%;
		display: block;
	}
	&.button-reverse {
		background: @link;
		&:hover {
			background: #098DC4;
			box-shadow: 0 1px 4px fade(@link,44%);
		}
		&:active {
			background: #084C81;
			box-shadow: none;
		}
	}
}