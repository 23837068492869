.textarea {
    height: 110px;
    resize: none;
    overflow: auto;
}

.textfield {
    height: 30px;
}

.textarea,
.textfield {
	-webkit-border-radius: 0;
    background: none;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: 0;
    padding: 3px 0;
    font-size: 20px;
    line-height: 24px;
	font-family: @font;
    color: @text;
    -webkit-appearance: none;
    transition: color @hover;
    .placeholder(#999);
    &.parsley-error {
        .placeholder(@linkhover);
        color: @linkhover;
    }
}