.settings-form {
	border: 6px solid @link;
	border-radius: 5px;
	background: @bg;
	padding: 40px;
	.resp(1279px,{
		padding: 30px;
	});
	.resp(767px,{
		padding: 20px;
	});
	.form {
		box-shadow: 0 5px 10px fade(#98c2d3,36%);
		> fieldset {
			.resp(767px,{
				box-shadow: 0 5px 10px fade(#98c2d3,36%);
			});
			&:last-child {
				.resp(767px,{
					box-shadow: 0 5px 10px fade(#98c2d3,36%);
				});
			}
		}
	}
}

.settings-form-block {
	padding-bottom: 0;
}