.page-404 {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0;
	.button {
		flex: 0 0 auto;
		.resp(767px,{
			height: 50px;
			line-height: 50px;
		});
		.resp(420px,{
			width: 100%;
			padding: 0;
			display: block;
		});
	}
	.container {
		flex: 0 0 auto;
		flex-direction: column;
		align-items: center;
		display: flex;
		text-align: center;
	}
	.page-404-content {
		flex: 0 1 auto;
		padding-top: 74px;
		text-align: center;
		.resp(1050px,{
			padding-top: 30px;
		});
		.resp(420px,{
			padding-top: 20px;
		});
	}	
	.page-404-copyright {
		font-size: 16px;
		line-height: 20px;
		color: #999;
		padding: 40px 0;
		.resp(1050px,{
			padding: 30px 0;
		});
	}
	.page-404-header {
		font-weight: bold;
		font-size: 42px;
		line-height: 48px;
		margin-bottom: 14px;
		.resp(800px,{
			padding-top: 35px;
			font-size: 32px;
			line-height: 38px;
		});
		.resp(767px,{
			font-size: 28px;
			line-height: 32px;
			.orientation(landscape,{
				padding: 0;
			});
		});
		.resp(420px,{
			max-width: 260px;
			padding-top: 15px;
			font-size: 24px;
			line-height: 28px;
		});
	}
	.page-404-image {
		display: block;
		.resp(800px,{
			display: none;
		});
	}
	.page-404-image-mobile {
		display: none;
		.resp(800px,{
			display: block;
		});
		.resp(767px,{
			height: 150px;
			margin: 0 auto;
			.orientation(landscape,{
				display: none;
			});
		});
	}
	.page-404-logo {
		font-weight: bold;
		font-size: 38px;
		line-height: 42px;
		text-transform: uppercase;
		margin-bottom: 15px;
		display: inline-block;
		vertical-align: top;
		.resp(767px,{
			margin-bottom: 5px;
		});
		&:hover {
			> span {
				color: @link;
			}
		}
		> span {
			color: @text;
			font-size: 20px;
			font-weight: normal;
			position: relative;
			top: -10px;
			margin-right: 3px;
			transition: color @hover;
		}
	}
	.page-404-text {
		font-size: 26px;
		line-height: 32px;
		margin-bottom: 43px;
		flex: 0 0 auto;
		.resp(800px,{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 20px;
		});
		.resp(420px,{
			font-size: 16px;
			line-height: 24px;
		});
	}
}