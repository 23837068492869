header {
	background: #FFF;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	border-bottom: 1px solid #EAEAEA;
	transition: border-color @hover, box-shadow @hover, transform @hover;
	.scrolled & {
		border-color: #FFF;
		box-shadow: 0 10px 21px fade(#989898,36%);
	}
	.downscroll & {
		transform: translateY(-100%);
	}
	.homepage & {
		border-color: transparent;
	}
	.upcroll & {
		transform: translateY(0);
	}
	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 90px;
		transition: height @hover;
		.scrolled & {
			height: 80px;
		}
	}
	.logo {
		font-weight: bold;
		font-size: 38px;
		line-height: 42px;
		text-transform: uppercase;
		flex: 0 0 auto;
		display: inline-block;
		vertical-align: top;
		&:hover {
			> span {
				color: @link;
				animation: pulse 1s ease-in-out infinite;
			}
		}
		> span {
			color: @text;
			font-size: 20px;
			font-weight: normal;
			position: relative;
			top: -5px;
			margin-right: 3px;
			transition: color @hover;
			float: left;
		}
	}
	.nav {
		flex: 0 0 auto;
		&.no-transition {
			transition: none;
		}
		.resp(1050px,{
			position: fixed;
			right: 0;
			top: 0;
			transform: translateX(311px);
			width: 311px;
			box-sizing: border-box;
			height: 100%;
			background: #FFF;
			z-index: 1000;
			transition: transform @move;
			will-change: transform;
			display: flex;
			flex-direction: column;
			padding: 0;
			.nav-mobile-opened & {
				transform: translateX(0);
			}
		});
		.resp(400px,{
			transform: translateX(280px);
			width: 280px;
		});
		.nav-menu {
			.resp(1050px,{
				flex: 1 1 auto;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
			});
			> ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
				display: flex;
				.resp(1050px,{
					display: block;
					overflow: hidden;
				});
				> li {
					font-size: 20px;
					line-height: 24px;
					margin-left: 46px;
					height: 90px;
					display: flex;
					align-items: center;
					transition: height @hover;
					.resp(1279px,{
						margin-left: 35px;
					});
					.resp(1050px,{
						height: auto;
						border-top: 2px solid @bg;
						margin: 0;
						display: block;
						height: auto;
					});
					.scrolled & {
						height: 80px;
						.resp(1050px,{
							height: auto;
						});
					}
					&:first-child {
						margin: 0;
						.resp(1050px,{
							border: 0;
						});
					}
					> a {
						flex: 0 0 auto;
						.resp(1050px,{
							display: block;
							padding: 13px 30px;
						});
					}
				}
			}
			.current-menu-item {
				position: relative;
				&:after {
					position: absolute;
					left: -10%;
					bottom: -1px;
					height: 2px;
					width: 120%;
					content: '';
					background: @link;
					.resp(767px,{
						display: none;
					});
				}
				> a {
					color: @text;
				}
			}
            .sub-menu {
                z-index: 1000;
                .current-menu-item {
                    &:after {
                        display:none !important;
                }}
            }
			.menu-item-has-children {
				position: relative;
				&.opened {
					.resp(1050px,{
						background: @bg;
					});
					> a {
						color: @linkhover;
						&:after {
							transform: rotate(180deg);
							.svg('../img/darr.svg',@linkhover);
						}
					}
					> ul {
						opacity: 1;
						visibility: visible;
						transform: translateY(-7px);
						.resp(1050px,{
							transform: none;
							display: block;
							padding: 0 0 20px 0;
						});
					}
				}
				> a {
					padding-right: 20px;
					position: relative;
					.resp(1050px,{
						padding-right: 50px;
					});
					&:hover {
						&:after {
							.svg('../img/darr.svg',@linkhover);
						}
					}
					&:after {
						width: 11px;
						height: 5px;
						position: absolute;
						right: 0;
						top: 50%;
						content: '';
						transform: translateY(-50%);
						margin-top: 2px;
						transition: background @hover, transform @hover;
						.svg('../img/darr.svg',@link);
						.resp(1050px,{
							right: 20px;
						});
					}
				}
				> ul {
					position: absolute;
					left: -32px;
					top: 100%;
					transform: translateX(-7px);
					transition: all @hover;
					opacity: 0;
					visibility: hidden;
					border: 2px solid @link;
					box-sizing: border-box;
					border-radius: 5px;
					padding: 20px 28px;
					box-shadow: 0 10px 21px fade(#989898,36%);
					background: #FFF;
					margin: 0;
					list-style-type: none;
					.resp(1050px,{
						opacity: 1;
						visibility: visible;
						display: none;
						transform: none;
						transition: none;
						border: 0;
						background: none;
						box-shadow: none;
						border-radius: 0;
						position: relative;
						left: 0;
						top: 0;
						margin: 0;
					});
					> li {
						margin-top: 12px;
						white-space: nowrap;
						font-size: 18px;
						.resp(1050px,{
							white-space: normal;
							margin-top: 10px;
							padding: 0 30px;
						});
						&:first-child {
							margin: 0;
						}
					}
				}
			}
		}
		.nav-mobile-top {
			display: none;
			flex: 0 0 auto;
			padding-right: 20px;
			justify-content: flex-end;
			align-items: center;
			border-bottom: 2px solid @bg;
			.resp(1050px,{
				display: flex;
			});
			height: 90px;
			transition: height @hover;
			.scrolled & {
				height: 80px;
			}
			.nav-mobile-close {
				margin: 0;
				padding: 0;
				outline: 0;
				cursor: pointer;
				border: 0;
				width: 24px;
				height: 24px;
				-webkit-border-radius: 0;
				-webkit-appearance: none;
				.svg('../img/close.svg',@link);
				flex: 0 0 auto;
			}
		}
	}
	.nav-mobile-open {
		margin: 0;
		padding: 0;
		outline: 0;
		cursor: pointer;
		border: 0;
		flex: 0 0 auto;
		display: none;
		-webkit-border-radius: 0;
		-webkit-appearance: none;
		width: 32px;
		height: 3px;
		background: @link;
		position: relative;
		transition: background @hover;
		.resp(1050px,{
			display: block;
		});
		&:hover {
			background: @linkhover;
			&:after,
			&:before {
				border-color: @linkhover;
			}
		}
		&:after {
			border-bottom: 3px solid @link;
			top: 100%;
		}
		&:before {
			border-top: 3px solid @link;
			bottom: 100%;
		}
		&:after,
		&:before {
			width: 32px;
			height: 6px;
			position: absolute;
			left: 0;
			content: '';
			transition: border-color @hover;
		}
	}
	.nav-mobile-overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background: @link;
		opacity: 0;
		visibility: hidden;
		transition: all @hover;
		display: none;
		.nav-mobile-opened & {
			opacity: 0.88;
			visibility: visible;
		}
		.resp(1050px,{
			display: block;
			will-change: all;
		});
	}
}