.case-problem {
	background: @bg;
	padding: 35px 0 54px 0;
	.resp(767px,{
		padding: 30px 0 50px 0;
	});
	h2 {
		width: 208px;
		margin: 0;
		flex: 0 0 auto;
		color: @link;
		.resp(1050px,{
			width: 150px;
			text-align: left;
		});
		.resp(767px,{
			width: auto;
			text-align: center;
			margin-bottom: 15px;
		});
	}
	.case-problem-info {
		flex: 1 1 auto;
		padding-top: 15px;
		.resp(1050px,{
			padding-top: 7px;
		});
		> p {
			&:last-child {
				margin: 0;
			}
		}
		> span {
			font-size: 24px;
			line-height: 28px;
			display: block;
			margin-bottom: 20px;
			.resp(767px,{
				font-size: 20px;
				margin-bottom: 10px;
			});
		}
	}
	.container {
		display: flex;
		.resp(767px,{
			display: block;
		});
	}
}