.news-header {
	font-size: 22px;
	line-height: 32px;
	margin-bottom: 18px;
	.resp(767px,{
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 10px;
	});
}

.news-image {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	border-radius: 5px;
	transition: filter @hover;
	.resp(767px,{
		margin-bottom: 10px;
	});
	&:hover {
		filter: grayscale(1);
	}
}

.news-info {
	display: flex;
	margin-bottom: 13px;
	justify-content: space-between;
	align-items: flex-start;
	line-height: 18px;
	.news-category {
		color: @linkhover;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		margin-right: 15px;
		flex: 0 1 auto;
	}
	.news-views {
		font-size: 16px;
		flex: 0 0 auto;
		padding-left: 21px;
		background: url('../img/eye.svg') no-repeat left 5px;
		background-size: 15px 10px;
	}
}

.news-more {
	font-size: 16px;
	line-height: 24px;
	display: inline-block;
	vertical-align: top;
	font-weight: bold;
	text-transform: uppercase;
}

.news-text {
	font-size: 16px;
	line-height: 24px;
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 13px;
	.resp(767px,{
		font-size: 15px;
		line-height: 20px;
	});
	&:after {
		position: absolute;
		left: -10px;
		bottom: 0;
		width: 50px;
		height: 1px;
		content: '';
		background: @linkhover;
		.resp(1050px,{
			left: 0;
		});
	}
}